export const lmsBranch = [
    'E-WISE',
    'E-WISE',
    'E-WISE',
    'E-WISE',
    'E-WISE',
    'E-WISE',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ];