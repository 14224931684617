export const lmsProducts = [
    [
    {id: 1, title: 'Schoolleiders PO (+VO)', stud: 3, studLoggedIn: 3, activeStud: 3, activeStudPercentage: 100, totFinished: 86, avgCourses: 28.7, avgCoursesActive: 28.7, points: 154.00, avgPoints: 51.3, avgPointsActive: 51.3,}, 
    {id: 2, title: 'Voortgezet onderwijs', stud: 47, studLoggedIn: 44, activeStud: 39, activeStudPercentage: 83, totFinished: 158, avgCourses: 3.4, avgCoursesActive: 4.1, points: 289.00, avgPoints: 6.1, avgPointsActive: 7.4,}
    ],
    [{id: 3, title: 'Voortgezet Onderwijs - Ondersteunend personeel'}],
    [{id: 4, title: 'Primair onderwijs'},
        {id: 5, title: 'Primair onderwijs (+VO)'},
        {id: 6, title: 'Primair Onderwijs - Ondersteunend personeel'},
        {id: 7, title: 'Schoolleiders PO (+VO)'},
    ],
    [{id: 8, title: 'Voortgezet onderwijs'},
        {id: 9, title: 'Voortgezet onderwijs (+PO)'}
    ],
    [{id: 21, title: 'Primair onderwijs'}],
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ];