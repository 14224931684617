export const bannersTitles = [
    'Banner 1',
    'Banner 2',
    'Banner 3',
    'Banner 4',
    'Banner 5',
    'Banner 6',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ];