export const productsCourses = [
    [{id: 19, title: 'Advocaat'}, {id: 20, title: 'Advocaat proefcursus'}],
    [{id: 20, title: 'Advocaat proefcursus'}],
    [{id: 21, title: 'Accountant'}],
    [{id: 22, title: 'Accountant proefcursus'}],
    [{id: 21, title: 'Alliance Healthcare'}, {id: 22, title: 'Accountant proefcursus'}],
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ];