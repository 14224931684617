export const lmsManagers = [
    [
      {id: 'amiah-pruitt', email: 'olen_legros@gmail.com', firstname: 'Olen', lastname: 'Legros'},
      {id: '', email: 'iris.magnee@e-wise.nl', firstname: 'Iris', lastname: 'Magnée'}
    ],
    [{id: '', email: 'stephan_csorba@gmail.com', firstname: 'Stephan', lastname: 'Csorba'}],
    [{id: 'amiah-pruitt', email: 'olen_legros@gmail.com', firstname: 'Olen', lastname: 'Legros'}],
    [{id: '', email: 'stephan_csorba@gmail.com', firstname: 'Stephan', lastname: 'Csorba'}],
    [{id: '', email: 'stephan_csorba@gmail.com', firstname: 'Stephan', lastname: 'Csorba'}],
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ];