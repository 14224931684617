export const trialsCourses = [
    [{id: 1, title: 'Kindermishandeling signaleren en melden', status: 'open'},{id: 2, title: 'Duizeligheid in de huisartsenpraktijk', status:'afgerond'}],
    [{id: 1, title: 'Kindermishandeling signaleren en melden', status: 'afgerond'},{id: 2, title: 'Duizeligheid in de huisartsenpraktijk', status:'open'}],
    [{id: 1, title: 'Cursus x', status: 'afgerond'},{id: 2, title: 'Cursus Y', status:'open'}],
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
];