export const bannerStatus = [
    'active',
    'expired',
    'scheduled',
    'active',
    'scheduled',
    'scheduled',
    'expired',
    'expired',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ];