export const trialsStudents = [
    [{id: 'amiah-pruitt', email: 'olen_legros@gmail.com', firstname: 'Olen', lastname: 'Legros'}],
    [{id: '', email: 'stephan_csorba@gmail.com', firstname: 'Stephan', lastname: 'Csorba'}],
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
];