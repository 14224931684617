export const visibility = [
    [{id: 2, title: 'Customers'}],
    [{id: 1, title: 'Clients'}],
    [{id: 2, title: 'Customers'}],
    [{id: 1, title: 'Clients'}],
    [{id: 2, title: 'Customers'}],
    [{id: 1, title: 'Clients'}],
    [{id: 1, title: 'Clients'}],
    [{id: 1, title: 'Clients'}, {id: 2, title: 'Customers'}],
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ];