export const quality = [
    'green',
    'orange',
    'red',
    'green',
    'green',
    'orange',
    'red',
    'orange',
    'green',
    'red',
    'red',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ];