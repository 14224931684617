export const lmsCompanies = [
    'AMS Dependance EINSTEIN CLASS',
    'csg Anna Maria van Schurman',
    'Onderwijsstichting De Wijnberg',
    'De Anger',
    'Dokter Herman Bekiusschool',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ];