// components
// import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

// const getIcon = (home) => <Iconify icon={home} sx={{ width: 1, height: 1 }} />;

const vendor = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: "New Orders",
        path: "/dashboard/vendor",
        icon: "ant-design:dashboard-filled",
      },
     
    ],
  },
  
];

export default vendor;
